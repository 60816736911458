import axios from "axios";

const useSpreadSheet = () => {
  const _getData = async () => {
    const spreadsheet_id = `1P0rGa_Etum9s7QkCUt8_YRj0xkYDDH3CK-jxQA_QTMI`;
    const google_api_key = `AIzaSyDFbXw3PmFGtZ6Wfg2jrR6-MWCgFe903tM`;
    const { data: d } = await axios.get(
      `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheet_id}/values/Sheet1!A1:Z1000?key=${google_api_key}`
    );

    return d.values;
  };

  return {
    get: _getData,
  };
};

export default useSpreadSheet;

import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import Newsletter from "./Newsletter/Newsletter";
import Newsletter2 from "./Newsletter/Newsletter2";
import Socials from "./Socials";
import Footer from "./Footer";

export default function Home() {
  return (
    <Box className="font-aeternus-heavy background-texture">
      <Showcase />
      <Newsletter2 />
      <Socials />
      <Footer />
    </Box>
  );
}

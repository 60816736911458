import { Box, Image, Heading } from "../../../components/StyledComponent";
import { RoutePattern } from "../../../routes/RoutePattern";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/StyledComponent/InputField";
import SubscriptionController, {
  error,
} from "../../../api/Controller/SubcriptionController";
import {
  GeneralError,
  SubscriptionSubmitted,
  Success,
} from "../../../api/Controller/GSheetAPIController/Responds";
import Button from "../../../components/Form/Button";
import { useEffect } from "react";
import axios from "axios";
import useSpreadSheet from "../../Hooks/useSpreadSheet";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  #mailing-list-content {
    min-height: 0 !important;
    margin: 0 0 30px !important;
  }
`;

const Newsletter2 = () => {
  const { get } = useSpreadSheet();

  return (
    <Box
        id={RoutePattern.NEWSLETTER}
        className="relative p-10 pb-20 mb-24 md:mb-0 md:p-24 md:pb-0 md:pt-0 3xl:-mt-14 3xl:mb-10 flex flex-col w-full md:h-[70vh] md:min-h-min 3xl:h-[50vh] justify-center items-center flex-wrap"
    >
        <Box className="m-auto mt-0 md:-mt-10 md:mb-0 w-full md:w-2/5">
            <div className="text-left" id="newsletter_header">
                <Heading level={2} className="text-center">
                    <span className="text-5xl lg:text-6xl">SIGN UP FOR UPDATES</span>
                </Heading>
            </div>
            <Container id="mailing-list-container"></Container>
        </Box>
    </Box>
  );
};

export default Newsletter2;
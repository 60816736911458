import { Box } from "../../components/StyledComponent";
import {
    faInstagram,
    faTwitter,
    faSnapchat,
    faTiktok,
    faSpotify,
    faFacebook,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const IconContainer = styled.ul`
    li {
        display: inline-block;
        margin: 0 5px;
    }
`;

const Socials = () => {
    const today = new Date();
    return (
        <Box
            className="relative flex flex-col justify-center items-center px-5 pt-5 pb-2 text-center text-custom-red"
        >
            <IconContainer
                className="text-xl"
            >
                <li>
                    <a href="https://www.instagram.com/ifeelking" target="_blank">
                        <FontAwesomeIcon icon={faInstagram as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/ifeelkingog" target="_blank">
                        <FontAwesomeIcon icon={faTwitter as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://www.snapchat.com/add/ifeelkingxo" target="_blank">
                        <FontAwesomeIcon icon={faSnapchat as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@ifeelkingmusic" target="_blank">
                        <FontAwesomeIcon icon={faTiktok as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://open.spotify.com/artist/5NHm4TU5Twz7owibYxJfFU?si=slPm4qkbSsaMC-nkx9oxJA" target="_blank">
                        <FontAwesomeIcon icon={faSpotify as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/ifeelking/" target="_blank">
                        <FontAwesomeIcon icon={faFacebook as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCrtOnzd9dWH9lXTAB-64Hfg" target="_blank">
                        <FontAwesomeIcon icon={faYoutube as any} className="hover:scale-150 ease-in-out duration-300 text-2xl md:text-3xl" />
                    </a>
                </li>
            </IconContainer>
        </Box>
    );
};

export default Socials;

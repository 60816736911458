export enum RoutePattern {
  HOME = "home",
  RSVP = "RSVP",
  NEWSLETTER = "newsletter",

  ABOUT = "about",
  MUSIC = "music",
  VIDEOS = "videos",
  SUBSCRIBE = "subscribe",
}

import { HtmlHTMLAttributes } from "react";
import styled from "styled-components";
import { primary } from "../../constants";

const Divider = styled.div`
  content: "";
  width: 105%;
  left: -2.5%;
  z-index: 0;
  position: absolute;
  bottom: 5%;
  height: 0.6rem;
  background-color: ${primary};
`;

type HeadingProps = HtmlHTMLAttributes<HTMLHeadingElement> & {
  level?: number;
  className?: string;
};

const Heading = ({ level, className, ...props }: HeadingProps) => {
  switch (level) {
    case 2:
      return (
        <h2
          className={`text-2xl uppercase md:text-5xl font-aeternus-heavy tracking-wider font-bold z-20 ${className}`}
          {...props}
        >
          <span className="z-10 relative">{props.children}</span>
        </h2>
      );

    default:
      return (
        <h1
          className="text-center text-5xl font-vladimir-script md:text-8xl"
          {...props}
        >
          {props.children}
        </h1>
      );
  }
};

export default Heading;

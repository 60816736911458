import { Box } from "../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Footer = () => {
    const today = new Date();
    return (
        <Box
            className="relative flex flex-col justify-center items-center px-5 pb-5 text-2xl md:text-3xl leading-5 md:leading-6 text-white text-center"
        >
            <div className="show-on-mobile">COPYRIGHT &copy; {today.getFullYear()} WARNER MUSIC INDIA</div>
            <div>
                <span className="show-on-desktop">COPYRIGHT &copy; {today.getFullYear()} WARNER MUSIC INDIA{" "}|{" "}</span>{" "}<a href="https://www.wminewmedia.com/cookies-policy/?ot=090a03d4-f810-40fd-8376-bc24c0b574a2&url=www.wmg.com" target="_blank" rel="noreferrer">AD CHOICES</a>{" "}|{" "}<a href="https://www.wminewmedia.com/cookies-policy/?ot=090a03d4-f810-40fd-8376-bc24c0b574a2&url=www.wmg.com" target="_blank" rel="noreferrer">COOKIES POLICY</a>{" "}|{" "}<a className="ot-sdk-show-settings cursor-pointer uppercase">do not sell my personal information</a>
            </div>
        </Box>
    );
};

export default Footer;

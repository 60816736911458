import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { RoutePattern } from "../../../routes/RoutePattern";
import ALBUM_ART from "../../../assets/images/King Album Cover V4_Opt2.png";
import styled from "styled-components";

const AlbumIMG = styled.div`
  width: 40rem;
  height: 40rem;

  @media (max-width: 767px) {
    width: 24rem;
    height: 24rem;
  }
`;

const AlbumTitle = styled.div`
  
`;

const Button = styled.a`
  border-bottom: 2px solid #EA3939;
  color: #fff;
  cursor: pointer;
  transition: .2s ease-in-out 0s;

  :hover {
    transform: scale(1.1);
  }
`;

const Showcase = () => {
  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden py-0 -mt-8 md:-mt-10"
    >
      <AlbumIMG
        className="flex justify-center items-center"
      >
        <img src={ALBUM_ART} className="w-full h-full" />
      </AlbumIMG>
      {/* <AlbumTitle
        className="text-black text-center"
      >
        <div className="text-2xl md:text-5xl pt-5 md:pt-3 md:pt-10 uppercase font-semibold">Maan Meri Jaan (Afterlife)</div>
        <div className="text-base md:text-xl pt-2 md:pt-3 md:pt-10">An album by King and Nick Jonas</div>
      </AlbumTitle> */}
      <Button
        href="https://lnk.to/MaanMeriJaanAfterlife"
        target="_blank"
        className="text-5xl md:text-6xl px-8 md:px-12 md:-mt-10 uppercase whitespace-nowrap"
      >
        Listen Now
      </Button>
    </Box>
  );
};

export default Showcase;
